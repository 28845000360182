import { Close } from "assets/svg";
import ReactPortal from "components/ReactPortal";
import { useEffect } from "react";
import { Container, ModalContainer, ModalHeader, ModalContent } from "./styles";

const Modal = ({ title = "Modal", children, isOpen, handleClose }) => {
  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
    document.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);
  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <Container>
        <ModalContainer>
          <ModalHeader>
            <h2>{title}</h2>
            <span onClick={handleClose}>
              <Close width="30" height="30" outline="#fff" />
            </span>
          </ModalHeader>
          <ModalContent>{children}</ModalContent>
        </ModalContainer>
      </Container>
    </ReactPortal>
  );
};

export default Modal;

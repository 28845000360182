import styled from "styled-components";

export const ButtonUpload = styled.label`
  cursor: pointer;
  font-size: 1.8rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  line-height: normal;
  color: ${(p) => p.theme.colors.text.light};
  background-color: ${(p) => p.theme.colors.warning[900]};
  border: 1px solid ${(p) => p.theme.colors.dark[300]};
  & > input {
    display: none;
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.text.light};
  font-size: ${({ defaultSize }) => 1 * defaultSize}rem;
  font-family: "Open Sans";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > span {
    font-size: 2rem;
    font-weight: bold;
  }
`;

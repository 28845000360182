import { Label } from "./styles";

const Input = ({
  type = "text",
  value,
  onChange,
  label,
  name,
  checked,
  maxlength,
}) => {
  return (
    <Label>
      {label}
      <input
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        checked={checked}
        maxLength={maxlength}
      />
    </Label>
  );
};

export default Input;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.light[100]};
  background-color: ${({ theme }) => theme.colors.primary[800]};
  gap: 1rem;
  padding: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 3rem;
  }
`;

export const InputLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  @media (${({ theme }) => theme.medias.tablet}) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

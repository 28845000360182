import Header from "components/Header";
import styled from "styled-components";

const PrivateRouter = ({ children }) => {
  return (
    <>
      <Header />
      <Layout>{children}</Layout>
    </>
  );
};
export default PrivateRouter;

const Layout = styled.div`
  overflow-y: auto;
`;

import { Container } from "./styles";

const Logo = () => {
  return (
    <Container>
      <span>Save The Date</span>
      Creative Couples
    </Container>
  );
};

export default Logo;

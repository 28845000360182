import { ArrowLeft } from "assets/svg";
import Logo from "components/Logo";
import { Container } from "./styles";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <div onClick={() => navigate(-1)}>
        <ArrowLeft width="32" height="32" color="transparent" outline="white" />
      </div>
      <Logo />
      <div></div>
    </Container>
  );
};
export default Header;

import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route,
} from "react-router-dom";
import PublicRouter from "./PublicRouter";
import Main from "modules/printable/screens/Main";
import Login from "modules/login/screens/Main";
import PrivateRouter from "./PrivateRouter";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route
          path="/"
          element={
            <PrivateRouter>
              <Main />
            </PrivateRouter>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRouter>
              <Login />
            </PublicRouter>
          }
        />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default AppRouter;

import styled, { css } from "styled-components";
import RedBG from "assets/backgrounds/red.png";
import YellowBG from "assets/backgrounds/yellow.png";
import GreenBG from "assets/backgrounds/green.png";

export const Container = styled.div`
  position: relative;
`;

export const ImagePreviewArea = styled.div`
  & > img {
    aspect-ratio: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  margin-bottom: 30px;
`;

export const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.light[200]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 0;
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 3rem;
    align-items: flex-start;
  }
`;

export const PreviewArea = styled.div`
  height: 800px;
  max-height: 800px;
  min-height: 800px;
  width: 450px;
  max-width: 450px;
  min-width: 450px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.light[50]};
  padding: 5px 48px;
  ${({ variation }) => {
    if (variation === "white") {
      return css`
        background-color: ${({ theme }) => theme.colors.light[50]};
        color: ${({ theme }) => theme.colors.text.dark};
      `;
    }
    if (variation === "red") {
      return css`
        background-image: url(${RedBG});
        background-size: cover;
      `;
    }
    if (variation === "green") {
      return css`
        background-image: url(${GreenBG});
        background-size: cover;
      `;
    }
    if (variation === "yellow") {
      return css`
        background-image: url(${YellowBG});
        background-size: cover;
      `;
    }
    return css`
      background-color: ${({ theme }) => theme.colors.light[50]};
      color: ${({ theme }) => theme.colors.dark[400]};
    `;
  }}

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > h1 {
    font-family: "Hyldae Script";
    font-weight: 200;
    font-size: 78px;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  & > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    text-align: center;
    font-family: "Cormorant Light";
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
    vertical-align: middle;
    margin-bottom: 14px;
    letter-spacing: 3px;
    white-space: pre-line;
  }

  & > h3 {
    font-family: "Lovelyn";
    font-weight: 300;
    font-size: 17px;
    margin-bottom: 55px;
  }

  & > p {
    font-family: "Arapey Italic";
    font-size: 1.2rem;
  }
`;

export const PreviewDateArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Cyrillic Bodoni";
  height: 3.4rem;
`;
export const PreviewMonthValue = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[900]};
  border-top: 1px solid ${({ theme }) => theme.colors.dark[900]};

  font-size: 1.2rem;
  padding: 0.6rem 2rem;
  width: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PreviewDayValue = styled.div`
  font-family: "Bodoni Roman";
  padding: 0 1.2rem;
  padding-bottom: 5px;
  font-size: 4.2rem;
  line-height: 3.5rem;
`;
export const PreviewWeekValue = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark[900]};
  border-top: 1px solid ${({ theme }) => theme.colors.dark[900]};

  font-size: 1.2rem;
  padding: 0.6rem 1rem;
  width: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PreviewYearValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Bodoni Roman";
  font-size: 1.4rem;
  letter-spacing: 2px;
  margin-bottom: 3.8rem;
  padding-bottom: 5px;
`;

// Printable

export const PrintableContainer = styled.div`
  display: none;
  height: 100%;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 0;
  @media (${({ theme }) => theme.medias.tablet}) {
    padding: 3rem;
  }
`;

export const ImagePrintableArea = styled.div`
  & > img {
    aspect-ratio: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  margin-bottom: 70px;
`;

export const PrintableArea = styled.div`
  height: 1920px;
  width: 1080px;
  padding: 10px 115px;
  ${({ variation }) => {
    if (variation === "white") {
      return css`
        background-color: ${({ theme }) => theme.colors.light[50]};
        color: ${({ theme }) => theme.colors.text.dark};
      `;
    }
    if (variation === "red") {
      return css`
        background-image: url(${RedBG});
        background-size: cover;
      `;
    }
    if (variation === "green") {
      return css`
        background-image: url(${GreenBG});
        background-size: cover;
      `;
    }
    if (variation === "yellow") {
      return css`
        background-image: url(${YellowBG});
        background-size: cover;
      `;
    }
    return css`
      background-color: ${({ theme }) => theme.colors.light[50]};
      color: ${({ theme }) => theme.colors.dark[400]};
    `;
  }}

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > h1 {
    font-family: "Hyldae Script";
    font-weight: 200;
    font-size: 185px;
    margin-left: 20px;
    margin-bottom: 43px;
  }

  & > h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    text-align: center;
    font-family: "Cormorant Light";
    font-weight: 300;
    font-size: 70px;
    line-height: 80px;
    vertical-align: middle;
    margin-bottom: 50px;
    letter-spacing: 7px;
    white-space: pre-line;
  }

  & > h3 {
    font-family: "Lovelyn";
    font-weight: 300;
    font-size: 42px;
    margin-bottom: 120px;
  }

  & > p {
    font-family: "Arapey Italic";
    font-size: 30px;
  }
`;

export const DateArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Cyrillic Bodoni";
  height: 90px;
`;
export const MonthValue = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.dark[900]};
  border-top: 2px solid ${({ theme }) => theme.colors.dark[900]};

  font-size: 30px;
  padding: 16px 2px;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 5px;
`;
export const DayValue = styled.div`
  font-family: "Bodoni Roman";
  text-align: center;
  width: 180px;
  padding-bottom: 20px;
  font-size: 105px;
  letter-spacing: -2px;
`;
export const WeekValue = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.dark[900]};
  border-top: 2px solid ${({ theme }) => theme.colors.dark[900]};

  font-size: 30px;
  padding: 16px 2px;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 5px;
`;
export const YearValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Bodoni Roman";
  font-size: 37px;
  letter-spacing: 4px;
  margin-bottom: 95px;
`;

import { useContext, useMemo } from "react";
import GlobalContext from "context";
import {
  ImagePreviewArea,
  Container,
  PrintableArea,
  PrintableContainer,
  PreviewContainer,
  PreviewArea,
  DateArea,
  MonthValue,
  DayValue,
  WeekValue,
  YearValue,
  ImagePrintableArea,
  PreviewDateArea,
  PreviewMonthValue,
  PreviewDayValue,
  PreviewWeekValue,
  PreviewYearValue,
} from "./styles";
import Banner from "assets/images/banner.jpg";

const Preview = () => {
  const { firstName, secondName, date, variation, croppedImage, printEl } =
    useContext(GlobalContext);
  const eventDate = useMemo(() => new Date(date ? date : "2025/11/10"), [date]);

  const couplesName = useMemo(() => {
    if (firstName && secondName) {
      // short first names
      if (
        (firstName.length >= 7 &&
          firstName.length <= 14 &&
          secondName.length >= 14) ||
        (firstName.length === 6 && secondName.length >= 13) ||
        (firstName.length === 5 && secondName.length >= 12) ||
        (firstName.length === 4 && secondName.length >= 9) ||
        (firstName.length === 3 && secondName.length >= 9) ||
        (firstName.length === 2 && secondName.length >= 9) ||
        (firstName.length === 1 && secondName.length >= 7)
      ) {
        return `${firstName} &\n${secondName}`;
      }
      // short second names
      if (
        (secondName.length >= 7 &&
          secondName.length <= 14 &&
          firstName.length >= 14) ||
        (secondName.length === 6 && firstName.length >= 13) ||
        (secondName.length === 5 && firstName.length >= 12) ||
        (secondName.length === 4 && firstName.length >= 9) ||
        (secondName.length === 3 && firstName.length >= 9) ||
        (secondName.length === 2 && firstName.length >= 9) ||
        (secondName.length === 1 && firstName.length >= 7)
      ) {
        return `${firstName} \n& ${secondName}`;
      }

      if (firstName.length >= 15 || secondName.length >= 15) {
        if (firstName.length <= secondName.length) {
          return `${firstName} &\n${secondName}`;
        } else {
          return `${firstName} \n& ${secondName}`;
        }
      }
      if (firstName.length + secondName.length >= 22) {
        if (firstName.length <= secondName.length) {
          return `${firstName} &\n${secondName}`;
        } else {
          return `${firstName} \n& ${secondName}`;
        }
      }
      return `${firstName} & ${secondName}`;
    } else {
      return "Somebody & Someone";
    }
  }, [firstName, secondName]);

  return (
    <Container>
      <PreviewContainer>
        <PreviewArea variation={variation}>
          <h1>save the date</h1>
          <ImagePreviewArea>
            {croppedImage ? (
              <img src={croppedImage} className="cropped-img" alt="" />
            ) : (
              <img src={Banner} alt="banner" />
            )}
          </ImagePreviewArea>
          <h2>{couplesName}</h2>
          <h3>are getting married!</h3>

          <div>
            <PreviewDateArea>
              <PreviewMonthValue>
                {eventDate
                  .toLocaleString("en-US", { month: "long" })
                  .toUpperCase()}
              </PreviewMonthValue>
              <PreviewDayValue>
                {eventDate.toLocaleString("en-US", { day: "numeric" })}
              </PreviewDayValue>
              <PreviewWeekValue>
                {eventDate
                  .toLocaleString("en-US", { weekday: "long" })
                  .toUpperCase()}
              </PreviewWeekValue>
            </PreviewDateArea>
            <PreviewYearValue>
              {eventDate.toLocaleString("en-US", { year: "numeric" })}
            </PreviewYearValue>
          </div>
          <p>Formal invitation to follow</p>
        </PreviewArea>
      </PreviewContainer>
      <PrintableContainer>
        <PrintableArea ref={printEl} variation={variation}>
          <h1>save the date</h1>
          <ImagePrintableArea>
            {croppedImage ? (
              <img src={croppedImage} className="cropped-img" alt="" />
            ) : (
              <img src={Banner} alt="banner" />
            )}
          </ImagePrintableArea>
          <h2>{couplesName}</h2>
          <h3>are getting married!</h3>

          <div>
            <DateArea>
              <MonthValue>
                {eventDate
                  .toLocaleString("en-US", { month: "long" })
                  .toUpperCase()}
              </MonthValue>
              <DayValue>
                {eventDate.toLocaleString("en-US", { day: "numeric" })}
              </DayValue>
              <WeekValue>
                {eventDate
                  .toLocaleString("en-US", { weekday: "long" })
                  .toUpperCase()}
              </WeekValue>
            </DateArea>
            <YearValue>
              {eventDate.toLocaleString("en-US", { year: "numeric" })}
            </YearValue>
          </div>
          <p>Formal invitation to follow</p>
        </PrintableArea>
      </PrintableContainer>
    </Container>
  );
};
export default Preview;

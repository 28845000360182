import styled, { css } from "styled-components";

export const ButtonWrapper = styled.button`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${(p) => p.theme.colors.dark[300]};
  color: ${(p) => p.theme.colors.text.light};
  ${({ color }) => {
    if (color) {
      if (color === "cancel") {
        return css`
          background-color: ${(p) => p.theme.colors.dark[300]};
        `;
      }
      if (color === "delete") {
        return css`
          background-color: ${(p) => p.theme.colors.danger[500]};
        `;
      }
    } else {
      return css`
        background-color: ${(p) => p.theme.colors.warning[900]};
      `;
    }
  }}
`;

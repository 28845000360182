import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 21rem;
  font-size: 1.6rem;

  & > input {
    width: 100%;
    font-size: 1.8rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${(p) => p.theme.colors.dark[300]};
  }

  & > input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: ${({ theme }) => theme.colors.dark[700]};
    width: 3rem;
    height: 3rem;
    border: 2px solid currentColor;
    border-radius: 1rem;
    display: grid;
    place-content: center;
  }

  & > input[type="radio"]::before {
    content: "";
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${(p) => p.theme.colors.warning[900]};
  }

  & > input[type="radio"]:checked::before {
    transform: scale(1);
  }

  &:last-child {
    margin: 0;
  }
`;

import { ButtonUpload } from "./styles";

function FileInput({ onImageSelected }) {
  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  return (
    <ButtonUpload>
      Upload Image
      <input
        type="file"
        accept="image/*"
        onChange={handleOnChange}
        style={{}}
      />
    </ButtonUpload>
  );
}

export default FileInput;

import AppRouter from "routers/AppRouter";
import StyledProvider from "libs/styled-components";

function App() {
  return (
    <div className="App">
      <StyledProvider>
        <AppRouter />
      </StyledProvider>
    </div>
  );
}

export default App;

import { createContext, useRef, useState } from "react";

const GlobalContext = createContext({
  user: null,
  firstName: null,
  secondName: null,
  date: null,
  variation: null,
  croppedImage: null,
  uploadedImage: null,
  userHandler: () => {},
  firstNameHandler: () => {},
  secondNameHandler: () => {},
  dateHandler: () => {},
  variationHandler: () => {},
  croppedImageHandler: () => {},
  uploadedImageHandler: () => {},
});

export const GlobalContextProvider = (props) => {
  const [user, setUser] = useState();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [date, setDate] = useState("");
  const [variation, setColor] = useState("white");
  const [croppedImage, setImage] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  const printEl = useRef();

  const userHandler = (user) => {
    setUser(user);
  };

  const firstNameHandler = (firstName) => {
    setFirstName(firstName);
  };

  const secondNameHandler = (secondName) => {
    setSecondName(secondName);
  };

  const dateHandler = (date) => {
    setDate(date);
  };

  const variationHandler = (variation) => {
    setColor(variation);
  };

  const croppedImageHandler = (image) => {
    setImage(image);
  };

  const uploadedImageHandler = (image) => {
    setUploadedImage(image);
  };

  // states
  return (
    <GlobalContext.Provider
      value={{
        user,
        firstName,
        secondName,
        date,
        variation,
        croppedImage,
        uploadedImage,
        userHandler,
        firstNameHandler,
        secondNameHandler,
        dateHandler,
        variationHandler,
        croppedImageHandler,
        uploadedImageHandler,
        printEl,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;

import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
`;

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.text.light};
  width: 90%;
  height: 90%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 56px auto;
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.light};
  background-color: ${({ theme }) => theme.colors.dark[700]};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  & > h1,
  h2 {
    grid-column: 2/3;
    flex: 1;
  }
  & > span {
    cursor: pointer;
    grid-column: 3/4;
    display: flex;
    justify-self: end;
    padding: 0.5rem;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  overflow-y: auto;
`;

import styled, { createGlobalStyle } from "styled-components";
import "@fontsource/open-sans";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import hyldae from "assets/fonts/hyldae-script.ttf";
import lovelyn from "assets/fonts/lovelyn.ttf";
import bodoni from "assets/fonts/cyrillic-bodoni.ttf";
import arapey from "assets/fonts/arapey-italic.ttf";
import bodoniFLF from "assets/fonts/bodoniFLF-roman.ttf";
import cormorant from "assets/fonts/cormorantSC-light.ttf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Hyldae Script';
    font-weight: 400;
    src: local('Hyldae Script'), url(${hyldae}) format('truetype');
  }
  @font-face {
    font-family: 'Lovelyn';
    font-weight: 100 200 300 400 700;
    src: local('Lovelyn'), url(${lovelyn}) format('truetype');
  }
  @font-face {
    font-family: 'Cyrillic Bodoni';
    font-weight: 400;
    src: local('Cyrillic Bodoni'), url(${bodoni}) format('truetype');
  }
  @font-face {
    font-family: 'Bodoni Roman';
    font-weight: 400;
    src: local('Bodoni Roman'), url(${bodoniFLF}) format('truetype');
  }
  @font-face {
    font-family: 'Arapey Italic';
    font-weight: 400;
    src: local('Arapey Italic'), url(${arapey}) format('truetype');
  }
  @font-face {
    font-family: 'Cormorant Light';
    font-weight: 300;
    src: local('Cormorant Light'), url(${cormorant}) format('truetype');
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html{
    font-size: 62.5%;
    overflow: hidden;
    height: -webkit-fill-available;
  }
  html, body{
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.text.dark};
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.light[500]};;
  }
  button, input{
    font-family: "Roboto", sans-serif;
  }

  body{
    height: 100vh;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
  }

  h1, h2, h3{
    font-weight: 600;
  }

  h1{
    font-size: ${({ theme }) => theme.fontSizes.xxl};
  }


  h2{
    font-size: 2.2rem;
  }

  h3{
    font-size: 2rem;
  }
  p {
    font-size: 1.8rem;
  }
  form{
    width: 100%;
  }
  button{
    cursor: pointer;
  }

  #root, .App{
    height: 100%;
  }

  .App{
    
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 56px auto;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.light[200]};
    position: relative;
  }
`;

export default GlobalStyle;

export const StyledLayout = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  height: 100%;
  @media (${({ theme }) => theme.medias.tablet}) {
    display: grid;
    overflow: auto;
    grid-template-columns: 280px auto;
    grid-template-rows: auto;
    height: 100%;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
`;

export const StyledButtonArea = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const StyledList = styled.div`
  & > ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    position: relative;
    overflow-y: auto;
    gap: 1rem;

    & > li {
      padding: 1rem;
      background-color: ${({ theme }) => theme.colors.success[200]};
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;

      & header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
      }

      & > span {
        font-size: 1.8rem;
        font-weight: 600;
        margin-right: 1rem;
      }
      & > label {
        font-size: 1.8rem;
      }
    }
  }
`;

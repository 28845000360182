import { useContext, useState } from "react";
import GlobalContext from "context";
import FileInput from "components/FileInput";
import ImageCropper from "components/ImageCropper";
import { Container, InputGroup, ButtonArea, InputLine } from "./styles";
import Modal from "components/Modal";
import Button from "components/Button";
import * as htmlToImage from "html-to-image";
import Input from "components/Input";

const Form = () => {
  const {
    firstName,
    secondName,
    date,
    variation,
    uploadedImage,
    croppedImage,
    firstNameHandler,
    secondNameHandler,
    dateHandler,
    variationHandler,
    uploadedImageHandler,
    croppedImageHandler,
    printEl,
  } = useContext(GlobalContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(printEl.current, {
      canvasWidth: 1080,
      canvasHeight: 1920,
      width: 1080,
      height: 1920,
    });

    // download image
    const link = document.createElement("a");
    link.download = `${firstName}&${secondName}-${date}.png`;
    link.href = dataUrl;
    link.click();
  };

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    uploadedImageHandler(selectedImg);
    onOpenModal();
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = uploadedImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );
      const dataURL = canvasEle.toDataURL("image/jpeg");
      croppedImageHandler(dataURL);
      onCloseModal();
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    onCloseModal();
    if (!croppedImage) {
      uploadedImageHandler(null);
    }
  };

  return (
    <Container>
      <h1>Your data</h1>
      <>
        <InputLine>
          <Input
            label="First Name"
            maxlength={18}
            value={firstName}
            onChange={(e) => firstNameHandler(e.target.value)}
          />
          <Input
            label="Second Name"
            maxlength={18}
            value={secondName}
            onChange={(e) => secondNameHandler(e.target.value)}
          />
        </InputLine>

        <InputLine>
          <Input
            label="Date to Save"
            type="date"
            value={date}
            onChange={(e) => dateHandler(e.target.value)}
          />
          <InputGroup>
            <Input
              label="Elegant White"
              type="radio"
              value="white"
              name="variation"
              checked={variation === "white"}
              onChange={(e) => variationHandler(e.target.value)}
            />
            <Input
              label="Olive Green"
              type="radio"
              value="green"
              name="variation"
              checked={variation === "green"}
              onChange={(e) => variationHandler(e.target.value)}
            />
            <Input
              label="Sand Beach"
              type="radio"
              value="yellow"
              name="variation"
              checked={variation === "yellow"}
              onChange={(e) => variationHandler(e.target.value)}
            />
            <Input
              label="Light Rose"
              type="radio"
              value="red"
              name="variation"
              checked={variation === "red"}
              onChange={(e) => variationHandler(e.target.value)}
            />
          </InputGroup>
        </InputLine>
        <ButtonArea>
          {uploadedImage ? (
            <>
              <Button onClick={onOpenModal}>Edit Image</Button>

              <Button
                onClick={() => {
                  uploadedImageHandler(null);
                }}
              >
                Reset Image
              </Button>
            </>
          ) : (
            <FileInput
              setImage={uploadedImageHandler}
              onImageSelected={onImageSelected}
            />
          )}
        </ButtonArea>
      </>
      {firstName && secondName && date && croppedImage && (
        <Button onClick={downloadImage}>Download</Button>
      )}

      <Modal isOpen={isModalOpen} handleClose={onCloseModal} title="Edit Image">
        <ImageCropper
          image={uploadedImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      </Modal>
    </Container>
  );
};

export default Form;

import { StyledLayout } from "libs/styled-components/styles";
import Form from "modules/printable/components/Form";
import Preview from "modules/printable/components/Preview";

const Main = () => {
  return (
    <StyledLayout>
      <Form />
      <Preview />
    </StyledLayout>
  );
};
export default Main;
